import { dateOrToday, sanitize } from 'grifo-components';
import { GENERATE_ALL_BANK_SLIPS } from '../constants/account/AccountFieldsOptions';
import { accountPaymentCompleteBuilder } from "./AccountPaymentBuilder";
export const accountParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record?.id,
      operationId: values.operationId,
      personId: values.personId,
      value: values.value,
      amount: values.amount,
      date: values.competencyDate.format("YYYY-MM-DD"),
      shortComplement: values.shortComplement,
      longComplement: values.longComplement,
      referencedDocument: values.referencedDocument,
      resultCenters: resultCentersBuilder(values.resultCenters),
      documents64: values.documents64 ? sanitize(values.documents64, ['name', 'url']) : null,
      bankSlip: bankSlipBuilder(values),
      memberRegistrationId: values.memberRegistrationId,
      payments: values.payments.map(object => {
        return {
          ...object,
          paymentMethod: undefined,
          paymentCondition: undefined,
          date: undefined,
          methodKind: undefined,
          condition: undefined,
          bankSlip: undefined
        };
      })
    }
  };
};
export const resultCentersBuilder = (resultCenters = []) => {
  return resultCenters.filter(object => object.apportionment > 0).map(object => {
    return {
      id: object.id,
      resultCenterId: object.resultCenterId,
      value: object.value,
      apportionment: object.apportionment
    };
  });
};
const bankSlipBuilder = values => {
  let bankSlip = {};
  if (values.bankAccountId) bankSlip = {
    bankAccountId: values.bankAccountId,
    ourNumber: values.ourNumber,
    option: values.option,
    agreementId: values.agreementId,
    instruction1: values.instruction1,
    instruction2: values.instruction2,
    instruction3: values.instruction3,
    instruction4: values.instruction4,
    instruction5: values.instruction5
  };
  return bankSlip;
};
export const accountDefaultBuilder = record => {
  const object = record || {};
  const entry = record?.entry;
  return {
    id: object.id,
    operationId: object.operationId,
    personId: object.personId,
    person: object.person,
    value: object.value || 0.0,
    amount: object.value || 0.0,
    competencyDate: dateOrToday(object.date),
    dueDate: dateOrToday(object.dueDate),
    shortComplement: entry?.shortComplement,
    longComplement: entry?.longComplement,
    referencedDocument: entry?.referencedDocument,
    resultCenters: entry?.resultCenters,
    payments: accountPaymentCompleteBuilder(object),
    origin: object.origin,
    rescheduledAccount: object.rescheduledAccount,
    reschedulingAccount: object.reschedulingAccount,
    option: GENERATE_ALL_BANK_SLIPS,
    canEdit: object.canEdit,
    documents64: (entry?.documents64 || []).map(file => ({
      name: file.filename,
      status: 'done',
      uid: file.id,
      key: file.key,
      url: file.key,
      filename: file.filename,
      contentType: file.contentType,
      data: file.data
    }))
  };
};