import gql from "graphql-tag";
import { fiscalOperationListFragment } from "./OperationList";
import { paymentFragment } from "./payment";
import { FiscalContractItemFragment } from "./ContractItem";
import { fiscalPersonToCardFragment } from "./Person";
import { contractDocumentFragment } from "./ContractDocument";
import { addressFragment } from 'admin-front';
import { contractResultCenterFragment } from "./ContractResultCenter";
import { contractCategoryFragment } from "./ContractCategory";
import { InvoiceSimpleFragment } from "./InvoiceSimple";
export const ContractSimpleFragment = gql`
  fragment FiscalContractSimple on FiscalContract  {
    id,
    number,
    description,
    observation,
    status,
    start,
    finish,
    value,
    amount,
    balance,
    quantityBalance,
    administrateTax,
    completionPercentage,
    quantityCompletionPercentage,
    discardedAt,
    personId,
    addressId,
    operationId,
    operation {
      ...FiscalOperationList
    },
    payments {
      ...Payment
    }
    products {
      ...FiscalContractItem
    }
    infos {
      id,
      target,
      origin,
      info,
      additionalInformationId, 
    },
    resultCenters {
      ...ContractResultCenter
    }
    categories{
      ...ContractCategory
    }
   }
   ${FiscalContractItemFragment}
   ${paymentFragment}
   ${fiscalOperationListFragment}
   ${contractResultCenterFragment}
   ${contractCategoryFragment}
`;
export const ContractFragment = gql`
  fragment FiscalContract on FiscalContract  {
    ...FiscalContractSimple    
    address {
      ...Address
    },    
    person {
      ...FiscalPersonToCard
    },
    contractDocuments {
      ...ContractDocument
    },    
   }
   ${ContractSimpleFragment}
   ${fiscalPersonToCardFragment}
   ${contractDocumentFragment}
   ${addressFragment}   
`;
export const ContractListingFragment = gql`
  fragment ContractListing on FiscalContract  {
    ...FiscalContractSimple      
    address {
      id,
      alias
    },
    person{
      ...FiscalPersonToCard
    },
    invoices {
      ...InvoiceSimple
      operation {
        id,
        taxDocumentIssuer
      }
    },
   }
   ${ContractSimpleFragment}
   ${InvoiceSimpleFragment}
   ${fiscalPersonToCardFragment}   
`;