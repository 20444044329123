import gql from "graphql-tag";
import { addressFragment, phoneFragment, userStoreSimpleFragment, contactFragment, personDocumentFragment } from 'admin-front';
import { personBankingFragment } from "./PersonBanking";
export const financialPersonSimpleFragment = gql`
  fragment FinancialPersonSimple on FinancialPerson {
    id,
    name,
    tradeName,
    document,
    naturalRegistration,
    naturalRegistrationStateId,
    naturalRegistrationAgencyId,
    photo64,
    email,
    gender,
    birth,
    closure,
    rntrcCode,
    cadunicoCode,
    nisCode,
    jobRoleId,
    discardedAt,
    governmentInstitution, 
    nickname, 
    pisCode, 
    ctps, 
    voterRegistrationNumber, 
    voterRegistrationZone, 
    voterRegistrationSection, 
    nationalityId, 
    citizenshipId, 
    inss, 
    incomeId,
    lowIncome, 
    dependents, 
    occupationCode,
    isDriver,
    isAssociated,
    userId,
  }
`;
export const financialPersonFragment = gql`
  fragment FinancialPerson on FinancialPerson {
    ...FinancialPersonSimple,    
    mainAddress {
      ...Address
    },
    addresses {
      ...Address
    },
    phone {
      ...Phone
    },
    phones {
      ...Phone
    },    
    user {
      ...UserStoreSimple
    },
    ofCompany {
      id,
    }
    contacts {
      ...Contact
    }
    personDocuments {
      ...PersonDocument
    }
    personBankings {
      ...PersonBanking
    },
  }
  ${financialPersonSimpleFragment}
  ${addressFragment}
  ${phoneFragment}
  ${userStoreSimpleFragment}
  ${contactFragment}
  ${personDocumentFragment}
  ${personBankingFragment}
`;