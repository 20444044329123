import gql from "graphql-tag";
import { addressFragment } from "./Address";
import { phoneFragment } from "./Phone";
import { contactFragment } from "./Contact";
import { personDocumentFragment } from "./PersonDocument";
import { profileFragment } from "./Profile";
export const personSimpleFragment = gql`
  fragment PersonSimple on Person {
    id,
    name,
    tradeName,
    document,
    naturalRegistration,
    naturalRegistrationStateId,
    naturalRegistrationAgencyId,
    photo64,
    email,
    gender,
    birth,
    closure,
    rntrcCode,
    cadunicoCode,
    nisCode,
    jobRoleId,
    discardedAt,
    governmentInstitution, 
    nickname, 
    pisCode, 
    ctps, 
    voterRegistrationNumber, 
    voterRegistrationZone, 
    voterRegistrationSection, 
    nationalityId, 
    citizenshipId, 
    inss, 
    incomeId,
    lowIncome, 
    dependents, 
    occupationCode,
    isDriver,
    isAssociated,
    userId,
  }
`;
export const personFragment = gql`
  fragment Person on Person {
    ...PersonSimple,
    mainAddress {
      ...Address
    },
    addresses {
      ...Address
    },
    phone {
      ...Phone
    },
    phones {
      ...Phone
    },    
    user {
      id
    },
    ofCompany {
      id,
    }
    contacts {
      ...Contact
    }
    personDocuments {
      ...PersonDocument
    }
  }
  ${personSimpleFragment}
  ${addressFragment}
  ${phoneFragment}
  ${contactFragment}
  ${personDocumentFragment}
`;
export const personSearchFragment = gql`
  fragment PersonSearch on Person {
    ...PersonSimple,
    phone {
      ...Phone
    }
    addresses {
      ...Address
    }
    profile {
      ...Profile
    }
  }
  ${personSimpleFragment}
  ${phoneFragment}
  ${addressFragment}
  ${profileFragment}
`;