import gql from 'graphql-tag';
import { personFiscalInfoFragment, personCnaeFragment, personFiscalStFragment, personFiscalStartFragment, stateRegistrationFragment, certificateFragment } from "fiscal-front";
import { addressFragment, phoneFragment, userStoreSimpleFragment, contactFragment, personDocumentFragment } from "admin-front";
import { personBankingFragment } from "financial-front";
export const accountingPersonSimpleFragment = gql`
  fragment AccountingPersonSimple on AccountingPerson {
    id,
    name,
    tradeName,
    document,
    naturalRegistration,
    naturalRegistrationStateId,
    naturalRegistrationAgencyId,
    photo64,
    email,
    gender,
    birth,
    closure,
    rntrcCode,
    cadunicoCode,
    nisCode,
    jobRoleId,
    discardedAt,
    governmentInstitution, 
    nickname, 
    pisCode, 
    ctps, 
    voterRegistrationNumber, 
    voterRegistrationZone, 
    voterRegistrationSection, 
    nationalityId, 
    citizenshipId, 
    inss, 
    incomeId,
    lowIncome, 
    dependents, 
    occupationCode,
    isDriver,
    isAssociated,
    userId,
    contributeIcms,
    municipalRegistration,
    suframaRegistration,
    taxRegime,
    nfceToken,
    nfceId,
    environment,
    issueMode,
    producer, 
    scpIndicator,
    csllBasis,
    apurationPeriod,
    stockAvaliationMethod,
    presidentQualification,
    spedFrameworkProfile,
    icmsTaxableObligationCode,
    accountingActivity,
    regimeKind,
    creditApropriationKind,
    contributionKind,
    cumulativeRegime,
    specialSituation,
    specialSituationDate,
    bookNumber,
    nireCode,
    industryActivity,
    industryClassification,
  }
`;
export const accountingPersonFragment = gql`
  fragment AccountingPerson on AccountingPerson {
    ...AccountingPersonSimple,    
    mainAddress {
      ...Address
    },
    addresses {
      ...Address
    },
    phone {
      ...Phone
    },
    phones {
      ...Phone
    },    
    user {
      ...UserStoreSimple
    },
    ofCompany {
      id,
    }
    contacts {
      ...Contact
    }
    personDocuments {
      ...PersonDocument
    }
    personBankings {
      ...PersonBanking
    },
    stateRegistration,
    stateRegistrations {
      ...StateRegistration
    },
    cnaes {
      ...PersonCnae
    },
    sts {
      ...PersonFiscalSt
    },
    infos {
      ...PersonFiscalInfo
    },
    currentInfos {
      ...PersonFiscalInfo
    },    
    fiscalStarts {
      ...PersonFiscalStart
    },
    certificate {
      ...Certificate
    },
  }
  ${accountingPersonSimpleFragment}
  ${addressFragment}
  ${phoneFragment}
  ${userStoreSimpleFragment}
  ${contactFragment}
  ${personDocumentFragment}
  ${personBankingFragment}
  ${personCnaeFragment}
  ${personFiscalStFragment}
  ${personFiscalInfoFragment}
  ${personFiscalStartFragment}
  ${stateRegistrationFragment}
  ${certificateFragment}
`;