import { fiscalProductDefaultBuilder, fiscalProductParamBuilder } from 'fiscal-front';
export const accountingProductParamBuilder = (record, values) => {
  return {
    attributes: Object.assign({}, fiscalProductParamBuilder(record, values).attributes, {
      productGenreId: values.productGenreId
    })
  };
};
export const accountingProductDefaultBuilder = record => {
  return Object.assign({}, fiscalProductDefaultBuilder(record), {
    productGenreId: (record || {}).productGenreId
  });
};