import _ from "lodash";
import { precisionType, today } from 'grifo-components';
import { BILLED, COMPANY_LOCATION, FROM_INVOICE, NFE, OPENED, PERSON_LOCATION, WITHOUT_FREIGHT } from "../constants/invoices/InvoiceFieldsOptions";
import { INSIDE_STATE, IN_PERSON, MAKE_UP_TOTAL, WITHOUT_BROKER, NORMAL, COSTUMER_FINAL } from "../constants/taxProfile/TaxFieldsOptions";
import { FINAL_COSTUMER } from "../constants/operations/OperationsFieldsOptions";
const finalCostumer = values => {
  if (values.finalCostumerMethod != undefined) return values.finalCostumerMethod;
  return values.operationSubkind == FINAL_COSTUMER ? COSTUMER_FINAL : NORMAL;
};
export const groupInvoiceParamsBuilder = (record, values) => {
  let situation = values.action || values.situation || record.situation;
  return {
    attributes: {
      id: record ? record.id : null,
      origin: FROM_INVOICE,
      contractId: values.contractId,
      dfeNumber: values.dfeNumber,
      dfeSeries: values.dfeSeries,
      dfeModel: values.dfeModel,
      personId: values.personId,
      addressId: values.addressId,
      pickupMethod: values.pickupMethod,
      receiverId: values.receiverId,
      pickupLocationId: values.pickupLocationId,
      deliveryMethod: values.deliveryMethod,
      freightMethod: values.freightMethod,
      dispatcherId: values.dispatcherId,
      deliveryPlaceId: values.deliveryPlaceId,
      shipperId: values.shipperId,
      vehicleId: values.vehicleId,
      operationId: values.operationId,
      transactionId: values.transactionId,
      situation: situation,
      date: values.date ? values.date.format("YYYY-MM-DD") : undefined,
      issuanceDate: values.issuanceDate && situation == BILLED ? values.issuanceDate.format("YYYY-MM-DD") : undefined,
      departureDate: values.departureDate && situation == BILLED ? values.departureDate.format("YYYY-MM-DD") : undefined,
      operationDestination: values.operationDestination,
      finalCostumerMethod: finalCostumer(values),
      buyerPresenceIndicator: values.buyerPresenceIndicator || IN_PERSON,
      brokerIndicator: values.brokerIndicator || WITHOUT_BROKER,
      itemsTotal: values.itemsTotal,
      amount: values.itemsTotal,
      totalTaxes: values.totalTaxes,
      freightValue: values.freightValue,
      insuranceValue: values.insuranceValue,
      discountValue: values.discountValue,
      otherValue: values.otherValue,
      total: values.total,
      infos: values.infos || [],
      volumes: values.volumes || [],
      administrateTax: values.administrateTax,
      items: (values.items || []).map((object, index) => {
        const preparedAttributes = {
          invoiceIndex: index + 1,
          taxableQuantity: object.quantity,
          taxableUnitaryValue: object.unitaryValue,
          taxableGrossTotal: object.grossTotal,
          productId: object.productId || object.selectedProduct[0],
          productUnitId: object.productUnitId || object.selectedProduct[1],
          locationId: object.locationId || object.selectedProduct[2],
          batchId: object.batchId || object.selectedProduct[3]
        };
        delete object.personId;
        delete object.selectedProduct;
        delete object.productUnit;
        delete object.product;
        delete object.location;
        delete object.batch;
        if (situation !== BILLED) {
          delete object.icms;
          delete object.icmsDestination;
        }
        return {
          ...object,
          ...preparedAttributes
        };
      }),
      payments: record.situation === OPENED ? (values.payments || []).map(payment => {
        delete payment.id;
        return {
          ...payment
        };
      }) : null,
      invoiceIds: values.invoiceIds
    }
  };
};
const simpleInvoiceBuilder = (records, props = {}) => {
  const object = (records || [])[0] || {};
  return {
    invoiceNumber: object.invoiceNumber,
    dfeNumber: object.dfeNumber,
    dfeSeries: object.dfeSeries,
    dfeModel: object.dfeModel || props.dfeModel || NFE,
    person: object.person,
    personId: (object.person || {}).id,
    address: object.address,
    addressId: object.addressId || (object.address || {}).id,
    receiverId: (object.receiver || {}).id,
    pickupLocationId: (object.pickupLocation || {}).id,
    dispatcherId: (object.dispatcher || {}).id,
    deliveryPlaceId: (object.deliveryPlace || {}).id,
    pickupMethod: COMPANY_LOCATION,
    deliveryMethod: PERSON_LOCATION,
    freightMethod: WITHOUT_FREIGHT,
    shipperId: (object.shipper || {}).id,
    vehicleId: (object.vehicle || {}).id,
    operation: object.operation,
    operationId: object.operationId,
    transactionId: object.transactionId,
    situation: object.situation || OPENED,
    action: props.action,
    operationType: object.operationType,
    operationDestination: object.operationDestination || INSIDE_STATE,
    finalCostumerMethod: object.finalCostumerMethod || NORMAL,
    buyerPresenceIndicator: object.buyerPresenceIndicator || IN_PERSON,
    brokerIndicator: object.brokerIndicator || WITHOUT_BROKER,
    date: today(),
    issuanceDate: object.issuanceDate,
    departureDate: object.departureDate,
    issuancePurpose: object.issuancePurpose || NORMAL,
    totalTaxes: object.totalTaxes || 0,
    infos: object.infos || [],
    volumes: object.volumes || [],
    administrateTax: object.administrateTax,
    cooperativeAct: object.cooperativeAct,
    contractId: object.contractId
  };
};
export const agroupInvoiceBuilder = (records, props = {}) => {
  let invoiceIds;
  if (records) invoiceIds = records.map(record => {
    return record.id;
  });
  let agroupedInvoices = simpleInvoiceBuilder(records, props);
  let allItems = invoiceItemsBuilder(records);
  let payments = records[0].situation === OPENED ? paymentsBuilder(records) : records.map(record => record.payments || {}).flat();
  agroupedInvoices.items = allItems;
  agroupedInvoices.freightValue = _.sumBy(records, 'freightValue');
  agroupedInvoices.insuranceValue = _.sumBy(records, 'insuranceValue');
  agroupedInvoices.otherValue = _.sumBy(records, 'otherValue');
  agroupedInvoices.discountValue = _.sumBy(records, 'discountValue');
  agroupedInvoices.itemsTotal = _.sumBy(records, 'itemsTotal') || 0;
  agroupedInvoices.totalBeforePayment = _.sumBy(records, 'totalBeforePayment') || 0;
  agroupedInvoices.finalCostumerMethod = NORMAL, agroupedInvoices.buyerPresenceIndicator = IN_PERSON, agroupedInvoices.brokerIndicator = WITHOUT_BROKER, agroupedInvoices.payments = payments;
  agroupedInvoices.total = payments.reduce(function (previous, current) {
    return previous + (current.total || 0);
  }, 0);
  agroupedInvoices.invoiceIds = invoiceIds;
  return {
    ...agroupedInvoices
  };
};
const paymentsBuilder = records => {
  let payments = [];
  for (let index in records) {
    let recordPayments = records[index].payments;
    for (let paymentIndex in recordPayments) {
      let found = payments.findIndex(object => object.paymentMethodId === recordPayments[paymentIndex].paymentMethodId && object.paymentConditionId === recordPayments[paymentIndex].paymentConditionId);
      if (found < 0) payments.push(recordPayments[paymentIndex]);else {
        let prevPayment = payments[found];
        let actualPayment = recordPayments[paymentIndex];
        let newPayment = {
          ...prevPayment,
          conditionDiscount: (prevPayment.conditionDiscount || 0) + (actualPayment.conditionDiscount || 0),
          methodDiscount: (prevPayment.methodDiscount || 0) + (actualPayment.methodDiscount || 0),
          conditionFees: (prevPayment.conditionFees || 0) + (actualPayment.conditionFees || 0),
          conditionFeesRate: (prevPayment.conditionFeesRate || 0) + (actualPayment.conditionFeesRate || 0),
          methodFees: (prevPayment.methodFees || 0) + (actualPayment.methodFees || 0),
          methodFeesRate: (prevPayment.methodFeesRate || 0) + (actualPayment.methodFeesRate || 0),
          discount: (prevPayment.discount || 0) + (actualPayment.discount || 0),
          fees: (prevPayment.fees || 0) + (actualPayment.fees || 0),
          gracePeriod: (prevPayment.gracePeriod || 0) + (actualPayment.gracePeriod || 0),
          total: (prevPayment.total || 0) + (actualPayment.total || 0),
          valueToPay: (prevPayment.valueToPay || 0) + (actualPayment.valueToPay || 0)
        };
        newPayment.methodDiscountRate = 100 / (newPayment.total || 1) * newPayment.methodDiscount;
        newPayment.conditionDiscountRate = 100 / (newPayment.total || 1) * newPayment.conditionDiscount;
        payments[found] = newPayment;
      }
    }
  }
  return payments;
};
export const invoiceItemsBuilder = records => {
  let data = [];
  for (let index in records) {
    let personId = records[index].person.id;
    let items = records[index].items;
    if (!items) break;
    for (let i in items) {
      let item = items[i];
      let found = data.findIndex(object => object.productId === item.product.id && object.productUnitId === item.productUnitId && object.localId === item.localId && object.batchId === item.batchId && object.unitaryValue === item.unitaryValue && object.administrateTax === item.administrateTax && object.personId === personId);
      if (found < 0) {
        data.push({
          product: item.product,
          productId: item.productId,
          location: item.location,
          locationId: item.locationId,
          selectedProduct: item.selectedProduct,
          productUnitId: item.productUnitId,
          batch: item.batch,
          batchId: item.batchId,
          quantity: item.quantity,
          unitaryValue: precisionType(item.unitaryValue, 2),
          administrateTax: item.administrateTax,
          grossTotal: item.grossTotal,
          total: item.total,
          discountValue: item.discountValue,
          freightValue: item.freightValue,
          insuranceValue: item.insuranceValue,
          otherExpenses: item.otherExpenses,
          totalInvoiceMethod: MAKE_UP_TOTAL,
          personId: personId
        });
      } else {
        data[found].quantity += item.quantity;
        data[found].grossTotal += item.grossTotal;
        data[found].discountValue += item.discountValue;
        data[found].freightValue += item.freightValue;
        data[found].insuranceValue += item.insuranceValue;
        data[found].otherExpenses += item.otherExpenses;
        data[found].total += item.total;
      }
    }
  }
  return data;
};