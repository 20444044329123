import gql from "graphql-tag";
export const InvoiceSimpleFragment = gql`
  fragment InvoiceSimple on Invoice  { 
    id,
    ancestry,
    origin,
    subkind,
    siblingsCount,
    differentSituation,
    differentParentSituation,
    situationBeforeGrouped,
    fromRequest,
    transactionId,
    precisionType,
    operationType,
    operationDestination,
    finalCostumerMethod,
    buyerPresenceIndicator,
    brokerIndicator,
    taxDocumentIssuer,
    issuancePurpose,    
    contributeIcms,
    generatedBy,
    date,
    issuanceDate,
    departureDate,
    deliveryDate,
    expirationDate,
    accessKey,
    itemsTotal,
    totalTaxes,
    freightValue,
    insuranceValue,
    discountValue,
    otherValue,
    administrateTax,
    total,
    deliveryMethod,
    pickupMethod,
    pickupLocationId,
    deliveryPlaceId,
    dispatcherId,
    freightMethod,
    situation,
    invoiceNumber,
    dfeSeries,
    dfeModel,
    dfeNumber,
    canCancel,
    operationId,
    personId,
    addressId,
    contractId,
    companyId,
    receiverId,
    vehicleId,
    shipperId,
    referencedDocumentId,
    referencedDfe,
    referencedDfeNumber,
    referencedDfeSeries,
    referencedDfeDate,
  }
`;