import gql from "graphql-tag";
import { personFiscalInfoFragment } from "./PersonFiscalInfo";
import { personCnaeFragment } from "./PersonCnae";
import { personFiscalStFragment } from "./PersonFiscalSt";
import { personFiscalStartFragment } from "./PersonFiscalStart";
import { stateRegistrationFragment } from "./StateRegistration";
import { certificateFragment } from "./Certificate";
import { addressFragment, phoneFragment, userStoreSimpleFragment, contactFragment, personDocumentFragment } from "admin-front";
import { personBankingFragment } from "financial-front";
export const fiscalPersonSimpleFragment = gql`
  fragment FiscalPersonSimple on FiscalPerson {
    id,
    name,
    tradeName,
    document,
    naturalRegistration,
    naturalRegistrationStateId,
    naturalRegistrationAgencyId,
    photo64,
    email,
    gender,
    birth,
    closure,
    rntrcCode,
    cadunicoCode,
    nisCode,
    jobRoleId,
    discardedAt,
    governmentInstitution, 
    nickname, 
    pisCode, 
    ctps, 
    voterRegistrationNumber, 
    voterRegistrationZone, 
    voterRegistrationSection, 
    nationalityId, 
    citizenshipId, 
    inss, 
    incomeId,
    lowIncome, 
    dependents, 
    occupationCode,
    isDriver,
    isAssociated,
    userId,
    contributeIcms,
    municipalRegistration,
    suframaRegistration,
    taxRegime,
    nfceToken,
    nfceId,
    environment,
    issueMode,
    producer,    
  }
`;
export const fiscalPersonFragment = gql`
  fragment FiscalPerson on FiscalPerson {
    ...FiscalPersonSimple,    
    mainAddress {
      ...Address
    },
    addresses {
      ...Address
    },
    phone {
      ...Phone
    },
    phones {
      ...Phone
    },    
    user {
      ...UserStoreSimple
    },
    ofCompany {
      id,
    }
    contacts {
      ...Contact
    }
    personDocuments {
      ...PersonDocument
    }
    personBankings {
      ...PersonBanking
    },
    stateRegistration,
    stateRegistrations {
      ...StateRegistration
    },
    cnaes {
      ...PersonCnae
    },
    sts {
      ...PersonFiscalSt
    },
    infos {
      ...PersonFiscalInfo
    },
    currentInfos {
      ...PersonFiscalInfo
    },    
    fiscalStarts {
      ...PersonFiscalStart
    },
    certificate {
      ...Certificate
    },
  }
  ${fiscalPersonSimpleFragment}
  ${addressFragment}
  ${phoneFragment}
  ${userStoreSimpleFragment}
  ${contactFragment}
  ${personDocumentFragment}
  ${personBankingFragment}
  ${personCnaeFragment}
  ${personFiscalStFragment}
  ${personFiscalInfoFragment}
  ${personFiscalStartFragment}
  ${stateRegistrationFragment}
  ${certificateFragment}
`;
export const fiscalPersonToListFragment = gql`
  fragment FiscalPersonToList on FiscalPerson {
    ...FiscalPersonSimple,
    mainAddress {
      ...Address
    },
    user {
      ...UserStoreSimple
    },
    ofCompany {
      id,
    }
    contacts {
      ...Contact
    }
  }
  ${fiscalPersonSimpleFragment}
  ${addressFragment}
  ${userStoreSimpleFragment}
  ${contactFragment}
  
`;
export const fiscalPersonToCardFragment = gql`
  fragment FiscalPersonToCard on FiscalPerson {
    ...FiscalPersonSimple,
    phone {
      ...Phone
    }
    addresses {
      ...Address
    }
  }
  ${fiscalPersonSimpleFragment}
  ${phoneFragment}
  ${addressFragment}
`;