import gql from "graphql-tag";
export const financialDailyBalanceFragment = gql`
  fragment FinancialDailyBalance on FinancialDailyBalance  {
    id,
    currentAssetName,
    date,
    previous,
    credit,
    debit,
    balance,
  }
`;