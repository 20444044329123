export const DFE_HOMOLOGATION = 'homologation';
export const DFE_PRODUCTION = 'production';
export const DFE_PENDING_SUBMISSION = 'pending_submission';
export const DFE_PROCESSING = 'processing';
export const DFE_SENT = 'sent';
export const DFE_AUTHORIZED = 'authorized';
export const DFE_DENIED = 'denied';
export const DFE_REJECTED = 'rejected';
export const DFE_PROCESSING_CANCELLATION = 'processing_cancellation';
export const DFE_CANCELLED = 'cancelled';
export const DFE_NORMAL = 'normal';
export const DFE_CONTINGENCY_FS_IA = 'contingency_fs_ia';
export const DFE_CONTINGENCY_SCAN = 'contingency_scan';
export const DFE_CONTINGENCY_DPEC = 'contingency_dpec';
export const DFE_CONTINGENCY_FS_DA = 'contingency_fs_da';
export const DFE_CONTINGENCY_SVC_AN = 'contingency_svc_an';
export const DFE_CONTINGENCY_SVC_RS = 'contingency_svc_rs';
export const DFE_BATCH_QUERY = 'batch';
export const DFE_ENVIRONMENT_OPTIONS = [{
  value: DFE_HOMOLOGATION,
  label: 'Homologação'
}, {
  value: DFE_PRODUCTION,
  label: 'Produção'
}];
export const DFE_ISSUE_MODE_OPTIONS = [{
  value: DFE_NORMAL,
  label: 'Normal'
}, {
  value: DFE_CONTINGENCY_FS_IA,
  label: 'Contingência FS-IA',
  disabled: true
}, {
  value: DFE_CONTINGENCY_SCAN,
  label: 'Contingência SCAN',
  disabled: true
}, {
  value: DFE_CONTINGENCY_DPEC,
  label: 'Contingência DPEC',
  disabled: true
}, {
  value: DFE_CONTINGENCY_FS_DA,
  label: 'Contingência FS-DA',
  disabled: true
}, {
  value: DFE_CONTINGENCY_SVC_AN,
  label: 'Contingência SVC-AN'
}, {
  value: DFE_CONTINGENCY_SVC_RS,
  label: 'Contingência SVC-RS'
}];