import gql from "graphql-tag";
export const groupSimpleFragment = gql`
  fragment GroupSimple on Group {
    id,
    name,
    ancestry,
    fullPathName,
    discardedAt,
  }
`;
export const groupFragment = gql`
  fragment Group on Group  {
    ...GroupSimple
    parent {
      ...GroupSimple
    },
  }
  ${groupSimpleFragment}
`;
export const groupParentFragment = gql`
  fragment GroupParent on Group {
    ...Group
    children {
      ...Group,
      children {
        ...Group,
        children {
          ...Group,
          children {
            ...Group,
            children {
              ...Group,
            }
          }
        }
      }
    }
  }
  ${groupFragment} 
`;