import gql from "graphql-tag";
import { fiscalProductFragment } from "./Product";
import { fiscalProductUnitFragment } from "./ProductUnit";
import { batchFragment, locationFragment } from 'stock-front';
import { CfopFragment } from "./Cfop";
import { InvoiceItemAdditionalInfoFragment } from "./InvoiceItemAdditionalInfo";
import { invoiceItemTaxesCalculationFragment } from "./InvoiceItemTaxesCalculation";
import { InvoiceItemIcmsFragment } from "./InvoiceItemIcms";
import { invoiceItemCofinsFragment } from "./InvoiceItemCofins";
import { invoiceItemPisFragment } from "./InvoiceItemPis";
import { invoiceItemRetentionFragment } from "./InvoiceItemRetention";
import { invoiceItemIpiFragment } from "./InvoiceItemIpi";
export const InvoiceItemFragment = gql`
  fragment InvoiceItem on InvoiceItem  { 
    id,
    totalInvoiceMethod,
    cooperativeAct,
    invoiceIndex,
    quantity,
    totalReturned,
    returnedPercentage,
    unitaryValue,
    grossTotal,
    taxableQuantity,
    taxableUnitaryValue,
    taxableGrossTotal,
    freightValue,
    insuranceValue,
    discountValue,
    otherExpenses,
    totalTaxes,
    administrateTax,
    total,
    productId,
    productUnitId,
    locationId,
    batchId,
    manufacturingDate,  
    cfopId,  
    product {
      ...FiscalProduct
    }
    productLabel,
    productUnit {
      ...FiscalProductUnit
    }
    batch {
      ...Batch
    }
    location {
      ...Location
    }
  }
  ${fiscalProductFragment}
  ${fiscalProductUnitFragment}
  ${batchFragment}  
  ${locationFragment}
`;
export const taxInvoiceItemFragment = gql`
  fragment TaxInvoiceItem on InvoiceItem  { 
    ...InvoiceItem
    additionalInformations {
      ...InvoiceItemAdditionalInfo
    }
    icms {
      ...InvoiceItemIcms
    },  
    cofins {
      ...InvoiceItemCofins
    },  
    pis {
      ...InvoiceItemPis
    },  
    ipi {
      ...InvoiceItemIpi
    },  
    retentions {
      ...InvoiceItemRetention
    },
    cfop {
      ...Cfop
    }
    invoiceItemTaxesCalculation{
      ...InvoiceItemTaxesCalculation
    }
  }
  ${InvoiceItemFragment}
  ${invoiceItemCofinsFragment}
  ${invoiceItemPisFragment}
  ${invoiceItemRetentionFragment}
  ${invoiceItemIpiFragment}
  ${InvoiceItemIcmsFragment}
  ${invoiceItemTaxesCalculationFragment}   
  ${InvoiceItemAdditionalInfoFragment}
  ${CfopFragment}    
`;