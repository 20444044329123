import gql from "graphql-tag";
import { InvoiceFragment, TaxInvoiceFragment } from "./Invoice";
export const parentInvoiceFragment = gql`
  fragment ParentInvoice on Invoice {
    ...Invoice,
    parent {
      id,
    },
    children {
      ...Invoice,      
    }
  }
  ${InvoiceFragment}
`;
export const parentTaxInvoiceFragment = gql`
  fragment ParentTaxInvoice on Invoice {
    ...TaxInvoice,
    parent {
      id,
    },
    children {
      ...TaxInvoice,      
    }
  }
  ${TaxInvoiceFragment}
`;