import gql from "graphql-tag";
import { productUnitFragment } from "./ProductUnit";
import { batchFragment } from "./Batch";
import { stockOperationSimpleFragment } from "./Operation";
import { productFragment } from "./Product";
import { locationParentFragment } from "./Location";
export const movementFragment = gql`
  fragment StockMovement on StockMovement {
    id,
    quantity, 
    date, 
    transactionId,
    kind,
    subkind,
    operation {
      ...StockOperationSimple
    }    
    product {
      ...Product
    }
    productUnit {
      ...ProductUnit
    }
    location {
      ...LocationParent
    }
    batch {
      ...Batch
    }
  }
  ${stockOperationSimpleFragment}
  ${productFragment}
  ${productUnitFragment}
  ${locationParentFragment}
  ${batchFragment}
`;